<template>
  <div class="detail">
    <div class="container">
      <div class="crumbs">
        <el-breadcrumb
          class="defauult-breadcrumb"
          separator-class="el-icon-arrow-right"
        >
          <el-breadcrumb-item :to="{ path: '/grab-single' }"
            >抢单大厅</el-breadcrumb-item
          >
          <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        </el-breadcrumb>
        <!--支付状态 -->
        <div class="result" v-if="step_value == 2">
          <div>
            <i
              style="font-size: 50px; vertical-align: middle"
              class="el-icon-success"
            ></i>
            <span class="succeed" style="vertical-align: middle">抢单成功</span>
          </div>
          <el-button
            type="text"
            class="btn"
            @click="$router.push(`/personal-center/install/inservice`)"
          >
            查看订单
          </el-button>
        </div>
        <!-- 支付失败 -->
        <div class="result" v-else-if="step_value == 3">
          <div>
            <i style="font-size: 50px" class="el-icon-error"></i>
            <span class="succeed">抢单失败</span>
          </div>
          <div style="text-aling: center">
            <el-button type="text" class="btn" style="cocol:back;">
              有疑问，请联系管理员
            </el-button>
          </div>
        </div>
      </div>
      <div class="install-body centre_list" v-show="order_hiding">
        <div class="install-body-header">
          <div class="order">订单信息</div>
        </div>
        <div class="install-body-list">
          <table>
            <thead>
              <tr class="list-header">
                <th class="header-item item-310">
                  产品信息
                </th>
                <th class="header-item item-385">
                  客户信息
                </th>
                <th class="header-item item-220">
                  订单类型
                </th>
                <th class="header-item item-225">
                  结算收益
                </th>
              </tr>
            </thead>
          </table>
          <table>
            <tbody class="list-line-item">
              <tr class="sep-row">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td colspan="4" class="line-item-colspan">
                  <span class="order-number">
                    订单号：{{ list.orderNum }}
                  </span>
                  <span class="time"> 发布时间：{{ list.createDate }} </span>
                </td>
              </tr>
              <tr>
                <td class="line-item item-310">
                  <div class="img-or-name">
                    <img :src="$utils.picturePrefix(list.productImage)" />
                    <span>
                      {{ list.productName }}
                    </span>
                  </div>
                </td>
                <td class="line-item item-385">
                  <div class="real-name">
                    {{ $utils.formattedName(list.clientName) }}
                  </div>
                  <div class="address">
                    {{ list.clientAddress }}********
                    <p>****</p>
                  </div>
                  <div class="phone">
                    {{ $utils.formattedPhone(list.clientPhone) }}
                  </div>
                </td>
                <td class="line-item item-220">
                  <div class="order-type">
                    {{ $utils.orderTypeName(list.type) }}
                  </div>
                </td>
                <td class="line-item item-225">
                  <div class="order-money">
                    基础金额：￥{{ $utils.formatMoney(list.money) }}
                  </div>
                  <div class="order-money">
                    激励金额：￥{{ $utils.formatMoney(list.incentiveMoney) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="remarke-body">
          <div class="remarke-title">备注</div>
          <div class="remarke-text">{{ list.remarke || "暂无" }}</div>
        </div>
        <div style="color: #f3254e; padding: 20px 0;">
          * 接单后质保30天，如果30天内出现安装问题，接单师傅将自己进行解决
        </div>
        <!-- v-show="list.status == 1" -->
        <el-button
          type="danger"
          style="background-color: #f3254e;"
          @click="rob()"
        >
          立即抢单
        </el-button>
        <!-- <div v-show="list.status != 1" style="color: #f3254e;">
          * 该订单已被抢
        </div> -->
      </div>
    </div>
    <tip-modal :visible="visible" @close="visible = false" @ok="save">
      <div>
        接单<span style="color:red;">2个小时</span>
        后, 师傅仍未预约, 系统将回收订单
      </div>
    </tip-modal>
    <tip-modal
      :visible="robvisible"
      @close="robvisible = false"
      @ok="$router.push('/grab-single')"
    >
      <div>
        {{tip || '此订单已被其他师傅抢单成功'}}
      </div>
    </tip-modal>
  </div>
</template>

<script>
import TipModal from "@/components/TipModal";
export default {
  components: {
    TipModal
  },
  data() {
    return {
      //  订单详情数据
      list: {
        orderNum: "",
        auditTime: "",
        productImage: "",
        clientName: "",
        clientAddressDetail: "",
        clientPhone: "",
        type: "",
        money: ""
      },
      tip: null,
      step_value: 1,
      visible: false,
      robvisible: false,
      btn_hidden: true,
      order_hiding: true
    };
  },
  computed: {},
  watch: {},
  created() {
    this.query_list();
  },
  methods: {
    save() {
      // 点击抢单显示弹框
      this.visible = false;
      this.$api
        .installationOrderGrab({
          id: this.$route.query.id,
          userId: this.$store.getters.userId
        })
        .then(res => {
          let { code, msg } = res;
          if (code == 200) {
            this.step_value = 2;
            document.getElementById("app").scrollTop = 0;
            this.order_hiding = false;
            this.query_list();
            // this.$router.push("personal-center");
            this.visible = false;
            this.$message({
              type: "success",
              message: msg,
              duration: 2000
            });
          } else {
            this.step_value = 3;
            this.order_hiding = false;
            this.visible = false;
            this.$message({
              type: "error",
              message: msg,
              duration: 2000
            });
          }
        })
        .catch(err => {
          if (err.code == 202) {
            this.robvisible = true;
            this.tip = err.msg;
            // if (err.msg == "超出工作区域") {
            //   this.$message({
            //     type: "warning",
            //     message: err.msg,
            //     duration: 2000
            //   });
            // } else {

            // }
          } else {
            this.$message({
              type: "warning",
              message: err.msg,
              duration: 2000
            });
          }
          console.log(err);
        });
    },

    //  抢单页面
    query_list() {
      this.$api
        .getInstallationOrderDetail(this.$route.query.id)
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            this.list = data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    rob() {
      if (this.list.status == 1) {
        this.visible = true;
      } else {
        this.robvisible = true;
      }
      //
    }
  },

  mounted() {}
};
</script>
<style lang="less">
@import "./index.less";
</style>
